/*******************
 Flexible content: Tabs
 *******************/
jQuery('.tab').on("click", function() {
    let tabDataId = jQuery(this).data('id');
    let tabs = jQuery(this).parents('.tabs');

    // First remove all active classes
    tabs.find('.tab').removeClass('tab--active');
    tabs.find('.tabs__image-wrapper').removeClass('tabs__image-wrapper--active');

    // Then, add active classes to corresponding divs
    jQuery(this).addClass('tab--active');
    tabs.find('.tabs__image-wrapper[data-id=' + tabDataId + ']').addClass('tabs__image-wrapper--active');
});
