import Headroom from "headroom.js";

if(Headroom.cutsTheMustard) {
	var header_options = {
		offset : 64,
	};
	
	var pageTopHeight = jQuery('.page-top').outerHeight();
	var offsetHeight = pageTopHeight - 92;
	
	var scrollnav_options = {
		offset : offsetHeight,
	};
	
    if (null !== document.querySelector(".header")) {
		const header = document.querySelector(".header");
		const headroom_header = new Headroom(header, header_options);
		headroom_header.init();
		// console.log('header headroom');
	}
	
	if (null !== document.querySelector(".scroll-to-nav")) {
		const scroll_to_nav = document.querySelector(".scroll-to-nav");
		const headroom_scroll = new Headroom(scroll_to_nav, scrollnav_options);
		headroom_scroll.init();
		// console.log('scroll-to-nav headroom');
	}
}
